import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import XiaohongshuIcon from "../assets/images/xiaohongshu-icon.png";
import InstagramIcon from "../assets/images/ins-icon.png";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  marginTop: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SocialMediaIcons = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(4),
  marginBottom: theme.spacing(3),
}));

const ContactInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  flexWrap: "wrap",
  color: theme.palette.text.secondary,
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: "inherit",
  textDecoration: "none",
}));

const Footer = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer maxWidth={false}>
      {/* Social Media Icons */}
      <SocialMediaIcons>
        <StyledLink
          href="https://www.instagram.com/_yum_go/"
          target="_blank"
          aria-label="Instagram"
        >
          <img
            src={InstagramIcon}
            alt="Instagram"
            style={{ width: 35, height: 35 }}
          />
        </StyledLink>
        <StyledLink
          href="https://www.xiaohongshu.com/user/profile/66cf596c000000001d02219d"
          target="_blank"
          aria-label="Little Red Book"
        >
          <img
            src={XiaohongshuIcon}
            alt="Xiaohongshu"
            style={{ width: 35, height: 35 }}
          />
        </StyledLink>
      </SocialMediaIcons>

      {/* Contact Information */}
      <ContactInfo>
        <StyledLink
          href="mailto:support@yum-go.com"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <EmailIcon sx={{ marginRight: 1 }} />
          <Typography variant="body2">support@yum-go.com</Typography>
        </StyledLink>
      </ContactInfo>

      <Typography
        variant="body2"
        sx={{
          color: (theme) => theme.palette.text.secondary,
          marginTop: (theme) => theme.spacing(2),
        }}
      >
        © 2024 YumGo {t("all_rights_reserved")}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: (theme) => theme.palette.text.secondary,
          marginTop: (theme) => theme.spacing(2),
        }}
      >
        <RouterLink to="/privacy-policy">{t("privacy_policy")}</RouterLink> |{" "}
        <RouterLink to="/terms-and-conditions">
          {t("terms_and_conditions")}
        </RouterLink>
      </Typography>
    </StyledContainer>
  );
};

export default Footer;
