import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import LogoSlogan from "./components/LogoSlogan";
import MealsDisplay from "./components/MealsDisplay";
import MealsWithMap from "./components/MealsWithMap";
import MealPlans from "./components/MealPlans";
import SellingPoints from "./components/SellingPoints";
import AppScreenshots from "./components/AppScreenshots";
import Footer from "./components/Footer";
import TermsAndConditions from "./components/TermsAndConditions";
import theme from "./theme";
import { GlobalStyles } from "@mui/system";
import "../src/config/i18n";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalStyles styles={{ body: { margin: 0, padding: 0 } }} />
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <AppScreenshots />
                <LogoSlogan />
                <MealsDisplay />
                <MealsWithMap />
                <MealPlans />
                <SellingPoints />
              </>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
