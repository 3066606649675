import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import LanguageIcon from "@mui/icons-material/Language";
import TranslateIcon from "@mui/icons-material/Translate";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { styled, useTheme } from "@mui/material/styles";
import { Divider, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import LogoSvg from "../assets/images/yumgo-full.jpg";
import LanguageSelector from "./LanguageSelector";
import { Link } from "react-router-dom";

const CustomAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "white",
  width: "100%",
  boxShadow: "none",
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  boxShadow: "0px 6px 4px -2px rgba(0, 0, 0, 0.1)",
  borderTop: "none",
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const Logo = styled("img")(({ theme }) => ({
  height: "45px",
  marginRight: theme.spacing(1),
}));

const PartnerLink = styled("a")(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  fontSize: theme.fontSize.primary,
  fontWeight: 550,
  fontFamily: theme.typography.fontFamily,
}));

const RightContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const BoldListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiListItemText-primary": {
    fontWeight: "bold",
  },
}));

function Header() {
  const { t, i18n } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLanguageToggle = () => {
    setLanguageOpen(!languageOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguageOpen(false);
    setDrawerOpen(false); // Close the Drawer when a language is selected
  };

  return (
    <CustomAppBar position="static">
      <CustomToolbar>
        <LogoContainer>
          <Link to="/">
            <Logo src={LogoSvg} alt="Logo" />
          </Link>
        </LogoContainer>
        {isMobile ? (
          <>
            <CustomIconButton
              edge="end"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              {drawerOpen ? <CloseIcon /> : <MenuIcon />}
            </CustomIconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              <Box
                sx={{
                  width: 250,
                  display: "flex",
                  flexDirection: "column",
                  padding: 2,
                }}
              >
                <List>
                  {/* Language Selector */}
                  <ListItem button onClick={handleLanguageToggle}>
                    <ListItemIcon>
                      <TranslateIcon />
                    </ListItemIcon>
                    <BoldListItemText primary={t("language")} />
                    {languageOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={languageOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem button onClick={() => changeLanguage("en")}>
                        <ListItemIcon>
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary="English" />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => changeLanguage("zh-Hant")}
                      >
                        <ListItemIcon>
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary="繁體中文" />
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => changeLanguage("zh-Hans")}
                      >
                        <ListItemIcon>
                          <LanguageIcon />
                        </ListItemIcon>
                        <ListItemText primary="简体中文" />
                      </ListItem>
                    </List>
                  </Collapse>
                  <Divider />

                  {/* Join as Partner */}
                  <ListItem
                    button
                    component="a"
                    href="https://partner.yum-go.com"
                  >
                    <ListItemIcon>
                      <GroupAddIcon />
                    </ListItemIcon>
                    <BoldListItemText primary={t("join_as_partner")} />
                  </ListItem>
                  <Divider />
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <RightContainer>
            <LanguageSelector />
            <PartnerLink href="https://partner.yum-go.com">
              {t("join_as_partner")}
            </PartnerLink>
          </RightContainer>
        )}
      </CustomToolbar>
    </CustomAppBar>
  );
}

export default Header;
