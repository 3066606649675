import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  marginTop: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  // Retrieve the privacy policy text array from translations
  const policyText = t("privacy_policy_text", { returnObjects: true });

  return (
    <StyledContainer maxWidth="md">
      {/* Render the title */}
      <Typography variant="h4" align="center" gutterBottom>
        {policyText[0]}
      </Typography>

      {/* Render the "Last Updated" section */}
      <Typography variant="subtitle1" align="center" gutterBottom>
        {policyText[1]}
      </Typography>

      {/* Render the rest of the policy dynamically */}
      <Box>
        {policyText.slice(2).map((paragraph, index) => {
          // Render titles in bold (e.g., sections starting with "I.", "II.")
          if (/^[IVXLCDM]+\./.test(paragraph)) {
            return (
              <SectionTitle key={index} variant="h6">
                {paragraph}
              </SectionTitle>
            );
          }

          // Render regular paragraphs
          return (
            <Typography key={index} variant="body1" paragraph>
              {paragraph}
            </Typography>
          );
        })}
      </Box>
    </StyledContainer>
  );
};

export default PrivacyPolicy;
