import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  // Assuming "terms_conditions_text" is an array of strings in your JSON file
  const termsText = t("terms_conditions_text", { returnObjects: true });

  // Function to determine if a line is a title with a valid number prefix (e.g., 1., 15., 16.)
  const isMainNumberTitle = (text) => {
    return /^(\d{1,2}\.\s)(?!\d)/.test(text); // Matches 1. to 99., excludes 16.1, 16.2, etc.
  };

  return (
    <Container style={{ marginTop: "2em", marginBottom: "2em" }}>
      <Typography variant="h4" align="center" gutterBottom>
        {termsText[0]}
      </Typography>
      {termsText.slice(1).map((line, index) => (
        <Typography
          key={index}
          paragraph
          style={isMainNumberTitle(line) ? { fontWeight: "bold" } : {}}
        >
          {line}
        </Typography>
      ))}
    </Container>
  );
};

export default TermsAndConditions;
